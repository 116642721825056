/**
 * Constantes spécifiques alvéole
 */
export const vousetessur = 'Mes formations';
export const txtnombredoc = 'formation';
export const txtnombredocpluriel = 'formations';
export const titleAlveole = 'Découvrir nos formations';

// TODO arriver a faire fonctionner
export const alveoleLogoSvg = "./../images/icon-alv-formations.svg";

/************************ DeV ******************************************************/
// Récupération des infos WP
export const zAPIFormation = process.env.REACT_APP_API_NOS_FORMATIONS;
export const zAPIEtiquettes = process.env.REACT_APP_API_ETIQUETTES;
export const zAPILabels = process.env.REACT_APP_API_LABELS;
export const zUrlBack= process.env.REACT_APP_API_NOS_FORMATIONS_API;
export const zUrlBackNotifications = process.env.REACT_APP_API_SERVICENOTIFICATION;
/************************ LOCAL DeV ************************************************/

// export const zAPIFormation = "http://localhost:8000/api/formations";
// export const zAPIEtiquettes = "http://localhost:8000/api/etiquettes";
// export const zAPILabels = "http://localhost:8000/api/labels";
// export const zUrlBack = "http://localhost:8000"
// export const zUrlBackNotifications = "http://localhost:8001";

/**
 * Fin constantes spécifiques alvéole
 */

/************************ Constantes ***********************************************/
export const zURLhome = process.env.REACT_APP_HOME_CUSTOMER;
export const zURLprofile = process.env.REACT_APP_PROFILE;
